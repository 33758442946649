import type { Typology } from '@/server/api/buyer-front/types'

export const getDeliveryTermLabel = (deliveryDate: string, t: (key: string, params?: object) => string, onlyAffectsPast?: boolean) => {
  const currentDate = new Date(Date.now())
  currentDate.setHours(0, 0, 0, 0)

  const projectDeliveryDate = new Date(deliveryDate)

  const thresholdDate = new Date(currentDate.setMonth(currentDate.getMonth() + 3))
  const thresholdDatePast = new Date(currentDate.setMonth(currentDate.getMonth() - 3))

  const isImmediateCaseOne = (projectDeliveryDate <= thresholdDate) && !onlyAffectsPast
  const isImmediateCaseTwo = (projectDeliveryDate <= thresholdDatePast) && onlyAffectsPast

  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' }

  if (isImmediateCaseOne || isImmediateCaseTwo) return t('project.deliveryTerm.immediate')

  else if (onlyAffectsPast) return projectDeliveryDate.toLocaleDateString('es-ES', options)

  const remainingMonths = Math.ceil((projectDeliveryDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24 * 30))

  return t('project.deliveryTerm.deliverInMonths', { months: remainingMonths })
}

export const getDeliveryTypeLabel = (deliveryDate: string, t: (key: string, params?: object) => string) => {
  const currentDate = new Date(Date.now())
  currentDate.setHours(0, 0, 0, 0)

  const thresholdDate = new Date(currentDate.setMonth(currentDate.getMonth() + 3))
  const projectDeliveryDate = new Date(deliveryDate)
  if (projectDeliveryDate <= thresholdDate) return t('project.deliveryTerm.immediate')

  else return t('project.deliveryTerm.future')
}

export const getBedroomsLabel = (typologies: Typology [], t: (key: string, params?: object) => string) => {
  const sortedTypologiesByBedrooms = [...typologies.filter(a => a.bedroom !== undefined).sort((a, b) => a.bedroom - b.bedroom)]

  const minBedrooms = sortedTypologiesByBedrooms[0]?.bedroom
  const maxBedrooms = sortedTypologiesByBedrooms[sortedTypologiesByBedrooms.length - 1]?.bedroom

  if (minBedrooms === maxBedrooms) return t('project.bedrooms.exact', { n: minBedrooms })

  return t('project.bedrooms.range', { min: minBedrooms, max: maxBedrooms })
}

export const getBedroomsMin = (typologies: Typology []) => {
  const sortedTypologiesByBedrooms = [...typologies.filter(a => a.bedroom !== undefined).sort((a, b) => a.bedroom - b.bedroom)]

  const minBedrooms = sortedTypologiesByBedrooms[0]?.bedroom

  return minBedrooms
}

export const getAreaLabel = (typologies: Typology [], t: (key: string, params?: object) => string) => {
  const sortedTypologiesByBedrooms = [...typologies.filter(a => a.bedroom !== undefined).sort((a, b) => a.min_area - b.min_area)]

  const minArea = sortedTypologiesByBedrooms[0]?.min_area
  const maxArea = sortedTypologiesByBedrooms[sortedTypologiesByBedrooms.length - 1]?.min_area

  if (minArea === maxArea) return t('project.area.exact', { n: minArea })

  return t('project.area.range', { min: minArea, max: maxArea })
}

export const getProductTypeLabel = (productType: string, isShort: boolean, isSingular: boolean, t: (key: string, params?: object) => string) => {
  const lengthType = isShort ? 'short' : 'long'
  const pluralNumber = isSingular ? 1 : 2

  const productTypeLabel = t(`project.productType.${lengthType}.${productType}`, { n: pluralNumber })

  return productTypeLabel
}

const getIsConstructorContactActive = (businessHubCode: string, config): boolean => {
  const envKey = `CONTACT_CONSTRUCTOR_ACTIVE_${businessHubCode.toUpperCase()}`
  return config.public[envKey] === 'true'
}

export const getIsInPersonVisitActive = (listingId: string, businessHubCode: string, config): boolean => {
  const isConstructorContactActiveForHub = getIsConstructorContactActive(businessHubCode, config)
  const disabledListings = (config.public.LISTING_IDS_WITH_IN_PERSON_VISIT_DISABLED as string)?.split(',') || []
  const doesListingHaveInPersonVisitDisabled = disabledListings.includes(listingId)

  return isConstructorContactActiveForHub && !doesListingHaveInPersonVisitDisabled
}
